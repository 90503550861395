

<template>
  <div class="d-flex p-4 rounded bg-white bordered w-fit tabs">
    <template v-for="(tab) in tabs">

      <div
          v-if="tab.permission"
          v-bind:key="tab.name"
          class="nv_item d-flex pointer tab"
          @click="gotoPage(tab.path[0])"
      >
        <SvgIcon
            :class="{
                    'qp-tab-nav-is-active':tab.path.includes($route.path),
                    'qp-tab-nav':!tab.path.includes($route.path)
                  }"
            :text="tab.name"
            class="text-thin-gray"
        >
          <template v-slot:icon>
            <component :is="tab.icon" :component="tab.icon"/>
          </template>
        </SvgIcon>
      </div>
    </template>
  </div>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import GeneralIcon from "@/assets/images/qube/general.svg";
import PageAndContentIcon from "@/assets/images/qube/pages-and-content.svg";
import VenueServiceIcon from "@/assets/images/qube/venue-services.svg";
import QubeConfigIcon from "@/assets/images/qube/qube-config.svg";
import BlogsIcon from "@/assets/images/qube/blogs.svg";
import RestrictionsIcon from "@/assets/images/qube/restrictions.svg";
import MobileIcon from "@/assets/images/qube/mobile.svg";
import KioskIcon from "@/assets/images/qube/kiosk.svg";

export default {
  name: "B2cTabs",
  components: {
    SvgIcon,
    GeneralIcon,
    PageAndContentIcon,
    VenueServiceIcon,
    QubeConfigIcon,
    BlogsIcon,
    RestrictionsIcon,
    MobileIcon,
    KioskIcon
  },
  computed: {
    tabs() {
      return [
        {
          name: "General",
          icon: GeneralIcon,
          path: ["/b2c/general"],
          permission: true
        },
        {
          name: "Pages & Content",
          icon: PageAndContentIcon,
          path: ["/b2c/pages"],
          permission: true
        },
        {
          name: "Venue Services",
          icon: VenueServiceIcon,
          path: ["/b2c/sports"],
          permission: true
        },
        {
          name: "Qube Config",
          icon: QubeConfigIcon,
          path: ["/b2c/bookings-config"],
          permission: true
        },
        {
          name: "Qube App Config",
          icon: MobileIcon,
          path: ["/b2c/qube-app-config"],
          permission: true
        },
        {
          name: "Kiosk Config",
          icon: KioskIcon,
          path: ["/b2c/kiosk-config"],
          permission: this.checkReadPermission(this.$modules.kiosk.facility.slug)
        },
        {
          name: "Blogs",
          icon: BlogsIcon,
          path: ["/b2c/blogs"],
          permission: this.checkReadPermission(this.$modules.b2cconfig.blogs.slug)
        },
        {
          name: "Restrictions",
          icon: RestrictionsIcon,
          path: ["/b2c/customer-blacklist"],
          permission: this.checkReadPermission(this.$modules.b2cconfig.blacklist_management.slug)
        },
      ];
    },
  },
  methods:{
    gotoPage(path) {
      this.$router.push(path);
    },
  }
}
</script>
<style scoped>

</style>